import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sendOTP } from '../api/signup';
import {
  setEmail,
  setFirstName,
  setLastName,
  setMobile,
  setOtpId,
} from '../redux/reducers/signup-reducer';
import { emailRegex, mobileRegex } from '../utility/constant';
import { formatInput } from '../utility/utility';
import HeaderLogo from './logo/HeaderLogo';
import PageBottom from './page-bottom/PageBottom';
import Toast from './toast/Toast';

const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { firstName, lastName, email, mobile } = useSelector(
    (state) => state.signUpReducer.user
  );
  // const { emailVerified, mobileVerified } = useSelector(
  //   (state) => state.signUpReducer
  // );
  const [phoneNumber, setPhoneNo] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [phoneDisabled, setPhoneDisabled] = useState(false);

  const checkPath = () => {
    return location.pathname === '/register';
  };
  const formValidator = () => {
    let flag = false;
    if (emailError || phoneError || firstNameError || lastNameError) {
      flag = false;
    } else if (!firstName) {
      setFirstNameError('Enter first name');
    } else if (!lastName) {
      setLastNameError('Enter last name');
    } else if (!mobile) {
      setPhoneError('Please enter a valid Phone number');
    } else if (!email) {
      setEmailError('Please enter a email address');
    } else {
      flag = true;
    }
    return flag;
  };
  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  };
  const submit = () => {
    if (formValidator()) {
      let req = {
        client_id: process.env.REACT_APP_CLIENT_ID,
      };
      if (localStorage.getItem('email')) {
        req.mobile = mobile;
      } else {
        req.email = email;
      }
      sendOTP(req)
        .then((res) => {
          let otpId = res.data.otp_id;
          dispatch(setOtpId(otpId));
          if (!localStorage.getItem('email')) {
            localStorage.setItem('email', email);
            dispatch(setEmail(email));
            navigate('/email/otp/verify');
          } else {
            localStorage.setItem('mobile', mobile);
            dispatch(setMobile(mobile));
            navigate('/mobile/otp/verify');
          }
        })
        .catch((err) => {
          if (err?.response.data.non_field_errors) {
            Toast.error(err?.response.data.non_field_errors[0]);
          } else {
            Toast.error('Something went wrong');
          }
        });
    }
  };
  const handleEmailChange = (e) => {
    let { value } = e.target;
    let emailErr = '';
    if (!value) {
      emailErr = 'Please enter a email address';
    } else if (!emailRegex.test(value)) {
      emailErr = 'Please enter a valid email address';
    } else {
      emailErr = '';
    }
    if (isNaN(value)) {
      value = value.toLowerCase();
    }
    dispatch(setEmail(value));
    // setEmailAddress(value);
    setEmailError(emailErr);
  };

  const validateFirstChange = (e) => {
    const { value } = e.target;
    let firstNameErr = '';
    if (!value) {
      firstNameErr = 'Enter first name';
    }
    // setFirstName(value);
    dispatch(setFirstName(value));
    setFirstNameError(firstNameErr);
  };

  const validateLastChange = (e) => {
    const { value } = e.target;
    let lastNameErr = '';
    if (!value) {
      lastNameErr = 'Enter last name';
    }
    // setLastName(value);
    dispatch(setLastName(value));
    setLastNameError(lastNameErr);
  };

  const numberChange = (event) => {
    let phoneNumber = event.target.value || '',
      phoneErr = '';
    if (phoneNumber && !parseFloat(phoneNumber)) {
      phoneErr = 'Please enter a valid Phone number';
    } else if (phoneNumber && !phoneNumber.match(mobileRegex)) {
      phoneErr = 'Please enter a valid Phone number';
    } else {
      phoneErr = '';
    }
    setPhoneError(phoneErr);
    // setPhoneNo(phoneNumber);
    dispatch(setMobile(phoneNumber));
  };
  useEffect(() => {
    dispatch(setEmail(localStorage.getItem('email')));
    dispatch(setMobile(localStorage.getItem('mobile')));
    if (localStorage.getItem('email') !== '') {
      setEmailAddress(localStorage.getItem('email'));
      setEmailError('');
      setEmailDisabled(true);
      setPhoneDisabled(false);
    } else if (localStorage.getItem('mobile') !== '') {
      setPhoneNo(localStorage.getItem('mobile'));
      setPhoneError('');
      setPhoneDisabled(true);
      setEmailDisabled(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='row justify-content-center align-items-center flex-column mt-5'>
      <div className='col-sm-3 px-0'>
        <BrowserView>
          <div className=' py-5 px-3 colored-box'>
            <HeaderLogo />
            <div className='h4 font-weight-bold text-center'>
              Enter your details
            </div>
            <Form.Group className=' d-flex'>
              <div className='col-sm-6 pl-0 pr-2'>
                <Form.Control
                  placeholder='First name'
                  onChange={validateFirstChange}
                  value={firstName}
                  onKeyDown={enterHandler}
                  className={
                    'font-weight-medium ' + (firstNameError ? 'is-invalid' : '')
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {firstNameError}
                </Form.Control.Feedback>
              </div>
              <div className='col-sm-6 pr-0 pl-2'>
                <Form.Control
                  placeholder='Last name'
                  onChange={validateLastChange}
                  value={lastName}
                  onKeyDown={enterHandler}
                  className={
                    'font-weight-medium ' + (lastNameError ? 'is-invalid' : '')
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {lastNameError}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className=' px-0 md-10'>
              <Form.Control
                type='number'
                id='contact-number'
                placeholder='Mobile'
                onChange={numberChange}
                onKeyDown={(e) => {
                  formatInput(e, 9);
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    submit();
                  }
                }}
                value={phoneNumber}
                disabled={phoneDisabled}
                className={
                  'font-weight-medium ' + (phoneError ? 'is-invalid' : '')
                }
              />
              <Form.Control.Feedback type='invalid'>
                {phoneError}
              </Form.Control.Feedback>
              <Form.Control
                type='text'
                id='contact-number'
                placeholder='Email address'
                onChange={handleEmailChange}
                value={emailAddress}
                disabled={emailDisabled}
                onKeyDown={enterHandler}
                className={
                  'font-weight-medium mt-3 ' + (emailError ? 'is-invalid' : '')
                }
              />
              <Form.Control.Feedback type='invalid'>
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              {checkPath() ? (
                <Link to='/'></Link>
              ) : (
                <Link to='/register'></Link>
              )}
              <button
                type='button'
                className='btn btn-primary'
                onClick={submit}
              >
                Next
              </button>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className=' py-5 px-3 ml-3 mr-3 bg-light-grey'>
            <div className='h4 font-weight-bold text-center'>
              Enter your details
            </div>
            <Form.Group className='col-sm-12  px-0'>
              <Form.Label className='opacity-75 '>Enter First Name</Form.Label>
              <Form.Control
                placeholder=''
                onChange={validateFirstChange}
                value={firstName}
                className={
                  ' updated-input bg-light-grey font-weight-medium ' +
                  (firstNameError ? 'is-invalid' : '')
                }
                onKeyDown={enterHandler}
              />
              <Form.Control.Feedback type='invalid'>
                {firstNameError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='col-sm-12  px-0'>
              <Form.Label className='opacity-75 '>Enter Last Name</Form.Label>
              <Form.Control
                placeholder=''
                maxLength={phoneNumber}
                onChange={validateLastChange}
                value={lastName}
                className={
                  'updated-input bg-light-grey font-weight-medium ' +
                  (lastNameError ? 'is-invalid' : '')
                }
                onKeyDown={enterHandler}
              />
              <Form.Control.Feedback type='invalid'>
                {lastNameError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className=' px-0 md-10'>
              <Form.Label className='opacity-75 '>
                Enter Phone Number
              </Form.Label>
              <Form.Control
                type='number'
                id='contact-number'
                placeholder='Mobile'
                onChange={numberChange}
                onKeyDown={(e) => {
                  formatInput(e, 9);
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    submit();
                  }
                }}
                value={phoneNumber}
                disabled={phoneDisabled}
                className={
                  'updated-input bg-light-grey font-weight-medium ' +
                  (phoneError ? 'is-invalid' : '')
                }
              />
              <Form.Control.Feedback type='invalid'>
                {phoneError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className=' px-0 md-10'>
              <Form.Label className='opacity-75 '>Email Address</Form.Label>
              <Form.Control
                type='text'
                id='contact-number'
                placeholder='Email address'
                onChange={handleEmailChange}
                value={emailAddress}
                onKeyDown={enterHandler}
                disabled={emailDisabled}
                className={
                  'updated-input bg-light-grey font-weight-medium mt-3 ' +
                  (emailError ? 'is-invalid' : '')
                }
              />
              <Form.Control.Feedback type='invalid'>
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
            <div className='mt-5  justify-content-between align-items-center'>
              {checkPath() ? (
                <Link to='/'></Link>
              ) : (
                <Link to='/register'></Link>
              )}
              <button
                type='button'
                className='btn btn-primary w-100'
                onClick={submit}
              >
                Next
              </button>
            </div>
          </div>
        </MobileView>
        <PageBottom />
      </div>
    </div>
  );
};

export default Signup;
