import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/authentication';
import { signup } from '../api/signup';
import PageBottom from '../components/page-bottom/PageBottom';
import Toast from '../components/toast/Toast';
import { useAppSelector } from '../redux/hooks';
import { decryptData, encryptData } from '../utility/DecryptHelper';
import { getMFAppUrl } from '../utility/utility';
import { saveInfoAndPush, saveInfoWithKey } from './LoginSingupInfoManager';
import HeaderLogo from './logo/HeaderLogo';
import SuccessModal from './modal/SuccessModal';

const Password = () => {
  const { appURLs } = useAppSelector((state) => state.headerReducer);
  const navigate = useNavigate();
  const { firstName, lastName, email, mobile } = useSelector(
    (state) => state.signUpReducer.user
  );
  const [password, setPassword] = useState('');
  const [submitBtnState, setBtnState] = useState(true);
  const [passwordError, setPasswordError] = useState('');
  const [successfulModal, setSuccessfulModal] = useState({
    show: false,
    data: null,
  });
  const closeSuccessFulModal = () => {
    setSuccessfulModal({ show: false });
    saveInfoAndPush({}, '/', false, navigate);
  };

  const submit = () => {
    if (!submitBtnState) {
      let req = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        email: email,
        mobile: mobile,
        first_name: firstName,
        last_name: lastName,
        password: password,
      };

      signup(req)
        .then((res) => {
          signIn();
          Toast.info('You account has been created successfully.');
          // navigate('/')
        })
        .catch((err) => {
          Toast.error(err?.response?.data?.message);
        });
    }
  };

  const signIn = () => {
    const loginData = {
      username: email,
      password: password,
    };
    let strLogin = JSON.stringify(loginData) + '*#$*';
    let encryptedData = encryptData(strLogin).toString();
    const data = new FormData();
    data.append('auth_data', encryptedData);

    login(data).then((res) => {
      if (res.data.data) {
        const resData = decryptData(res.data.data);
        if (res.data.status.code === 1) {
          saveInfoWithKey('userData', res.data.data, '/', true);
          saveInfoWithKey('flowData', null, '/', false);
          const newData = window.btoa(
            unescape(encodeURIComponent(JSON.stringify(resData)))
          );
          window.location = getMFAppUrl(newData, appURLs['mf'], '');
        }
      }
    });
  };

  const passwordChange = (event) => {
    let password = event.target.value || '',
      passwordErr = '',
      submitBtnState = true;
    const passwordRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    if (!password) {
      passwordErr = 'Password can not be empty';
    } else if (!passwordRegex.test(password)) {
      passwordErr =
        'Password should have 1 lower case, 1 upper case, 1 numeric and one special character';
    } else {
      submitBtnState = false;
      passwordErr = '';
    }
    setPasswordError(passwordErr);
    setBtnState(submitBtnState);
    setPassword(password);
  };
  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  };
  return (
    <div className='row justify-content-center align-items-center h-100'>
      <div className='col-sm-3 px-0'>
        <BrowserView>
          <div className=' p-5 colored-box mb-6'>
            <HeaderLogo />
            <div className='h4 text-center heading-custom'>Set Password </div>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Label className='opacity-75 mb-3'>
                  Please Enter Your Password
                </Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter Password'
                  onChange={passwordChange}
                  value={password}
                  className={
                    'font-weight-medium ' + (passwordError ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {passwordError}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='mt-5 text-right'>
              <button
                type='button'
                className='btn btn-primary big-btn'
                onClick={submit}
                disabled={submitBtnState}
              >
                Next
              </button>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className=' p-5 bg-light-grey pb-6'>
            <div className='h4 text-center heading-custom'>Set Password </div>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <Form.Group className='col-12 px-0'>
                <Form.Label className='opacity-75 mb-3'>
                  Please Enter Your Password
                </Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter Password'
                  onChange={passwordChange}
                  value={password}
                  className={
                    'updated-input bg-light-grey font-weight-medium ' +
                    (passwordError ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {passwordError}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='pt-5 '>
              <button
                type='button'
                className='btn btn-primary big-btn w-100'
                onClick={submit}
                disabled={submitBtnState}
              >
                Next
              </button>
            </div>
          </div>
        </MobileView>

        {successfulModal.show ? (
          <SuccessModal
            show={successfulModal.show}
            onSubmit={closeSuccessFulModal}
            successText='Account has been created successfully. Please login'
            size='sm'
          />
        ) : null}
        <PageBottom />
      </div>
    </div>
  );
};
Password.propTypes = {
  location: PropTypes.object,
};
export default Password;
